<template>
  <tr>
    <td class="desktop-schedule-view__cell __date">
      <span class="color-neutral-600">{{ formattedDate(row.turn_date).dayOfWeek }}</span>
      <span class="ml-ex-6">{{ formattedDate(row.turn_date).dateFormatted }}</span>
    </td>
    <td class="desktop-schedule-view__cell __column">
      <div class="desktop-schedule-view__point">{{ row.point }}</div>
      <div class="desktop-schedule-view__coworkers mt-1">
        <span
          v-for="(coworker, index) in stringToArray(row.coworkers)"
          :key="index"
          class="desktop-schedule-view__coworker"
        >
          {{ coworker }}
        </span>
      </div>
    </td>
    <td class="desktop-schedule-view__cell">{{ formattedTime(row) }} - {{ formattedTime(row, false) }}</td>
    <td class="desktop-schedule-view__cell">{{ formattedSummarizeWorkTime(row) }}</td>
    <td class="desktop-schedule-view__cell">
      <base-tag v-if="row.turn_order === 0" class="desktop-schedule-view__tag" />
    </td>
    <td class="desktop-schedule-view__cell">
      <base-icon-button
        class="desktop-schedule-view__button"
        icon-path="arrow-up-right-line.svg"
        width="16"
        height="16"
        extra-small
        @click="openSchedule"
      />
    </td>
  </tr>
</template>

<script setup>
import { formattedDate } from "@/components-js/dateFormat";
import { formattedSummarizeWorkTime, formattedTime, stringToArray } from "../../helpers";
import BaseTag from "@/components/BaseTag";
import BaseIconButton from "@/components/BaseIconButton";

const emits = defineEmits(["openSchedule"]);
const props = defineProps({
  row: { type: Object },
});

const openSchedule = () => {
  emits("openSchedule", {
    name: "schedule-modal",
    currentPoint: { id: props.row.id_point, name: props.row.point },
    currentDate: props.row.turn_date,
    route: "personal-schedule-modal",
  });
};
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";
@import "@/styles/ui/breakPoints";
@import "@/styles/helpers";

.desktop-schedule-view {
  $borderSize: 2px;
  $borderColor: var(--color-neutral-100);

  &__cell {
    display: flex;
    align-items: center;
    padding: var(--sp-3) var(--sp-6);
    padding-right: unset;

    @include with-border(false, $borderSize, $borderColor, "bottom");
    @include add-font-face("body-1", false, true);
    @include text-truncate;

    &:first-child {
      padding-left: var(--sp-11);

      @media (max-width: 1178px) {
        padding-left: var(--sp-6);
      }
    }

    &:last-child {
      padding-right: var(--sp-4);
    }

    &.__column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__coworkers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--sp-1);
  }

  &__coworker {
    @include add-font-face("body-3", var(--color-neutral-400), true);

    &:not(:last-child) {
      &:after {
        content: "•";
        display: inline-flex;
        padding: 0 var(--sp-1);
        color: var(--color-neutral-900);
      }
    }
  }

  &__button {
    cursor: pointer;
  }
}

@include laptop-only {
  .desktop-schedule-view {
    &__cell {
      @include add-font-face("body-3", false, true);
    }
  }
}

@include mobile-only {
  .desktop-schedule-view {
    &__cell {
      padding: var(--sp-3) var(--sp-6);
      padding-right: unset;

      @include add-font-face("body-3");

      &:first-child {
        padding-left: var(--sp-8);
      }

      &:last-child {
        padding-right: var(--sp-4);
      }
    }
  }
}
</style>
