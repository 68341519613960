<template>
  <div class="shift-list-page new-design">
    <div class="shift-list-page__title">Мои смены</div>
    <personal-table-filters
      class="shift-list-page__filter-wrapper"
      :date-begin="date_begin"
      :date-end="date_end"
      :id-people="id_people?.toString()"
      :can-change-people="peopleFlag"
      :total-hours="total"
      @submit="handleSubmitFilter"
    />
    <shift-table class="shift-list-page__list-wrapper" :rows="report" />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vue2-helpers/vuex";
import { useRoute } from "vue2-helpers/vue-router";
import { addDays, endOfMonth, startOfMonth } from "date-fns";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { datesCheck } from "@/components-js/datesCheck";
import { dateFormatJS } from "@/components-js/dateFormat";
import { lS } from "@/components-js/localStorage";
import { request } from "@/components-js/requestSrv";
import ShiftTable from "./components/ShiftTable";
import PersonalTableFilters from "@/components/PersonalTableFilters";
import { formattedSummarizeWorkTime } from "@/views/ShiftListPage/helpers";

const checkDate = (date, isEnd) => {
  if (date.getDate() <= 15 && !isEnd) return startOfMonth(date);
  if (date.getDate() > 15 && !isEnd) return addDays(startOfMonth(date), 15);
  if (date.getDate() <= 15 && isEnd) return addDays(startOfMonth(date), 14);
  if (date.getDate() > 15 && isEnd) return endOfMonth(date);
};

const loStorage = new lS();
const date_begin = ref(dateFormatJS(checkDate(new Date())));
const date_end = ref(dateFormatJS(checkDate(new Date(), true)));
const id_people = ref(null);
const total = ref("");
const peopleFlag = ref(false);
const loading = ref(false);
const report = ref([]);
const coworkers = ref([]);
const columnSelected = ref([]);

const route = useRoute();
const store = useStore();
const user = computed(() => store?.state.user);
const isAdminsRole = computed(() => user.value?.roles?.includes("admin") || user.value?.roles?.includes("pointadmin"));
const pageTitle = computed(() => (isAdminsRole ? "Смены сотрудника списком" : "Мои смены"));

const getData = () => {
  let tmp = loStorage.getObjectProp(route.path, "date_begin");
  if (tmp) date_begin.value = tmp;

  tmp = loStorage.getObjectProp(route.path, "date_end");
  if (tmp) date_end.value = tmp;

  if (isAdminsRole.value) {
    // show people selector for admin
    peopleFlag.value = true;
    // get id_people from localStorage
    tmp = loStorage.getObjectProp(route.path, "id_people");
    if (tmp) id_people.value = tmp;
  } else {
    // user always see own table
    id_people.value = user.value?.uid;
  }

  tmp = loStorage.getObjectProp(route.path, "columnSelected");
  if (store?.state?.user?.roles?.includes("admin") && tmp) {
    for (let el of tmp) columnSelected.value.push(el);
  }
};

// retreive data anyway
const tableDataRetrieve = async () => {
  loading.value = true;

  const res = datesCheck(date_begin.value, date_end.value, DATE_MAX_INTERVAL);
  date_begin.value = dateFormatJS(res.date_begin);
  date_end.value = dateFormatJS(res.date_end);

  const data = await request(
    "/api/report/personal_turns",
    "POST",
    {
      id_people: id_people.value,
      date_begin: date_begin.value,
      date_end: date_end.value,
      columnSelected: columnSelected.value,
    },
    route.path
  );

  let totalHours = 0;
  let totalMinutes = 0;
  for (let shift of data) {
    const dataTime = formattedSummarizeWorkTime(shift, true);
    totalHours += dataTime.hours;
    totalMinutes += dataTime.minutes;
  }

  if (Number.isInteger(totalMinutes / 60)) {
    totalHours += totalMinutes / 60;
    total.value = `${totalHours} ч`;
  } else {
    total.value = `${totalHours + Math.floor(totalMinutes / 60)}ч ${totalMinutes % 60}м`;
  }

  if (total) {
    // if turns found, get coworkers
    coworkers.value = await request("/api/report/personal_turns_coworkers", "POST", {
      id_people: id_people.value,
      date_begin: date_begin.value,
      date_end: date_end.value,
    });

    // through each turn from data find coworkers
    let list = [];
    // !!! change in --> of
    // !!! data[i] --> i
    for (let trn of data) {
      list = coworkers.value.filter(
        el =>
          trn.turn_date === el.turn_date &&
          trn.id_point === el.id_point &&
          ((trn.utime_begin >= el.utime_begin && trn.utime_begin <= el.utime_end) ||
            (trn.utime_end > el.utime_begin && trn.utime_end <= el.utime_end) ||
            (el.utime_begin > trn.utime_begin && el.utime_end < trn.utime_end) ||
            (trn.utime_begin === el.utime_begin && trn.utime_end === el.utime_end))
        // ((el.utime_begin <= trn.utime_begin && el.utime_end > trn.utime_begin) ||
        //   (el.utime_begin < trn.utime_end && el.utime_end >= trn.utime_end) ||
        //   (el.utime_begin <= trn.utime_begin && el.utime_end >= trn.utime_end))
      );
      if (list.length) {
        // array of coworkers per turn
        trn.coworkers = "";
        for (let l in list.sort((a, b) => a.utime_end - b.utime_end)) // order y time_end desc
          trn.coworkers += (list[l].coworker_nickname ? list[l].coworker_nickname : list[l].coworker_name) + ", ";
      }
    }
  }
  report.value = data;
  loading.value = false;
};

getData();
tableDataRetrieve();

const handleSubmitFilter = filterData => {
  date_begin.value = filterData.dateBegin;
  date_end.value = filterData.dateEnd;
  id_people.value = filterData.idPeople;
  tableDataRetrieve();
};

const toChessTable = (date, point) => {
  route.push({ name: "Chesstable", params: { startDate: date, endDate: date, id_point: point } });
};
//
const visibleCheck = col => {
  return columnSelected.value?.includes(col);
};
</script>

<style lang="scss" scoped>
@import "@/styles/ui/fontSize";

.shift-list-page {
  padding: 0 var(--sp-8);

  &__title {
    display: none;
    @include add-font-face("h2");
  }

  &__filter-wrapper {
    padding-top: var(--sp-9);
    padding-bottom: var(--sp-6);
  }

  @include mobile-only {
    padding: var(--sp-6) var(--sp-4);

    &__title {
      display: block;
    }

    &__filter-wrapper {
      position: initial;
      margin-top: var(--sp-4);
      padding: 0 0 var(--sp-6);
    }
  }
}
</style>
